import { BaseBffHttpService } from '../BaseBffHttpService';

export type UpdateSelfTeamInviteRequestBody = {
  action: 'accept' | 'reject';
};

export type UpdateSelfTeamInviteResponse = {
  id: string;
  createdAt: Date;
};

export class TeamMembersHttpService extends BaseBffHttpService {
  basePath = 'team-members';

  updateSelfTeamInvite = async (
    inviteId: string,
    body: UpdateSelfTeamInviteRequestBody,
  ): Promise<UpdateSelfTeamInviteResponse> => {
    const response = await this.post<UpdateSelfTeamInviteRequestBody>(
      ['self', this.basePath, inviteId].join(this.pathSeparator),
      body,
    );

    return response.json();
  };

  deleteSelfTeamMember = async (teamId: string): Promise<void> => {
    await this.delete(['self', this.basePath, teamId].join(this.pathSeparator));
  };

  deleteTeamMember = async ({
    teamId,
    memberId,
  }: {
    teamId: string;
    memberId: string;
  }): Promise<void> => {
    await this.delete(`teams/${teamId}/members/${memberId}`);
  };
}
