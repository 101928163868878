<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>[ATHLETE NAME]</PageHeader>
        <div>
          <h1>Team Athlete Details</h1>
          <pre>teamId: {{ teamId }}</pre>
          <pre>athleteId: {{ athleteId }}</pre>
        </div>
      </div>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const { teamId, athleteId } = route.params;
</script>

<style scoped>
/* Add your styles here */
</style>
