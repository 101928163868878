<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20 flex flex-col">
        <PageHeader>
          Album <span v-if="album.name">"{{ album.name }}"</span>
        </PageHeader>
        <div v-if="isLoading" class="flex h-120 items-center justify-center">
          <ANSpinner />
        </div>

        <div v-else-if="album?.videos?.length">
          <InfoField class="mb-10 w-full sm:w-1/2 lg:w-1/3">
            Videos in album

            <template #description>
              {{ album.videos.length }}
            </template>
          </InfoField>

          <ResponsiveGrid :min-column-width="10" :gap="4">
            <div v-for="video in album.videos" :key="video.id" class="relative">
              <button
                type="button"
                class="absolute right-0 top-0 z-20 flex h-6 w-6 -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-black bg-opacity-80 transition duration-300 ease-in-out hover:bg-opacity-100"
                @click="removeId = video.id"
              >
                <TrashIcon class="h-3.5 w-3.5 text-white" />
              </button>

              <a
                :href="`/app/videos/${video.id}`"
                class="relative flex cursor-pointer flex-col overflow-hidden rounded-lg bg-black shadow-md"
              >
                <div
                  class="an-u-fs-0 flex h-24 flex-grow items-center justify-center bg-black text-white xs:h-36"
                >
                  <img
                    :id="video.id"
                    :src="getPoster(video)"
                    :alt="getName(video.blobName)"
                    class="absolute left-0 top-0 z-0 h-full w-full object-cover"
                  />
                </div>
              </a>
            </div>
          </ResponsiveGrid>
        </div>

        <EmptyState v-else class="mt-8 h-96">
          <template #illustration>
            <BuildingLibraryIcon class="h-24 w-24 text-teal-700" />
          </template>
          <template #description>
            Unfortunatelly, there are no videos uploaded
          </template>

          No Videos
        </EmptyState>
      </div>
    </GridContainer>
  </SidebarLayout>

  <RemoveConfirmationModal
    v-if="removeId"
    @close="removeId = ''"
    @confirm="removeVideo(removeId)"
  >
    <p class="mt-4 text-sm text-gray-500">
      Are you sure you want to remove this video from the album? This change is
      irreversible
    </p>
  </RemoveConfirmationModal>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { BuildingLibraryIcon, TrashIcon } from '@heroicons/vue/20/solid';
import { useRoute } from 'vue-router';
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import { getVideoName, getPoster } from 'src/core/Utils/VideoUtils';
import { ANSpinner } from 'src/components/Uikit/Components';
import InfoField from 'src/components/common/InfoField.vue';
import EmptyState from 'src/components/common/EmptyState.vue';
import AlbumsRepository from 'src/core/repositories/Albums/AlbumsRepository';
import ResponsiveGrid from 'src/components/common/ResponsiveGrid.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import RemoveConfirmationModal from 'src/components/Video/RemoveConfirmationModal.vue';

const albumsRepository = new AlbumsRepository();
const route = useRoute();

const isLoading = ref<boolean>(false);
const album = ref<IVideoAlbumEntry>({} as IVideoAlbumEntry);
const removeId = ref<string>('');

onMounted(async () => {
  await getAlbum();
});

const getAlbum = async () => {
  isLoading.value = true;

  const data = (await albumsRepository.getVideosInAlbum(
    route.params.key as string,
  )) as IVideoAlbumEntry;

  album.value = { ...data, videos: Object.values(data.videos ?? {}) };

  isLoading.value = false;
};

const getName = (blobName: string) => {
  return getVideoName(blobName);
};

const removeVideo = async (videoId: string) => {
  const { key } = route.params;

  if (!key) {
    return;
  }

  albumsRepository.removeVideoFromAlbum(key as string, videoId);

  await getAlbum();
};
</script>
