import { DEFAULT_PAGE_SIZE } from 'src/components/common/pagination/Pagination.types';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { BaseBffHttpService } from '../BaseBffHttpService';

export type PaginatedResponse<T> = {
  items: T[];
  pagination: PaginationData;
};

export type PaginationData = {
  total: number;
  take: number;
  skip: number;
};

export class VideoHttpService extends BaseBffHttpService {
  basePath = 'videos';

  async getVideos<T = IVideo[]>(
    skip = 0,
    take = DEFAULT_PAGE_SIZE,
  ): Promise<T> {
    const response = await this.get(this.basePath, {
      skip: `${skip}`,
      take: `${take}`,
    });

    return response.json();
  }

  async getVideo(id: string): Promise<IVideo> {
    const response = await this.get(`${this.basePath}/${id}`);

    return response.json();
  }

  async add(formData: FormData): Promise<Response> {
    return this.post<FormData>(`${this.basePath}/add`, formData);
  }

  async addTeamVideo(teamId: string, formData: FormData): Promise<Response> {
    return this.post<FormData>(`team/${teamId}/${this.basePath}/add`, formData);
  }

  async deleteVideo(id: string): Promise<Response> {
    return this.delete(`${this.basePath}/${id}`);
  }
}
