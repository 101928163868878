const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const getDisplayDateTime = (date: string) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};

export const getShortDisplayDate = (date: string | Date) => {
  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeZone,
  }).format(new Date(date));
};
