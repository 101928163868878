import { BaseBffHttpService } from '../BaseBffHttpService';

type AccountsCreateRequest = {
  email: string;
  password: string;
};

type AccountsCreateResponse = {
  token: string;
};

export class AccountsHttpService extends BaseBffHttpService {
  basePath = 'accounts';
  #path = 'accounts';

  create = async ({
    email,
    password,
  }: AccountsCreateRequest): Promise<AccountsCreateResponse> => {
    const response = await this.post(this.#path, {
      email,
      password,
    });

    if (!response.ok) {
      throw new Error('Failed to create account');
    }

    const { token } = await response.json();

    return {
      token,
    };
  };

  deleteAccount = async (): Promise<void> => {
    await this.delete(this.#path);
  };

  getSelfAccount = () => {
    return this.get(['self', 'account'].join(this.pathSeparator));
  };
}
