import { FrameTagDto, FrameTagResponseDto } from 'src/core/services/FrameTags';

export const mapToFrameTagDto = (tag: FrameTagResponseDto): FrameTagDto => {
  return {
    id: tag.id || Date.now().toString(),
    name: tag.name,
    offsetX: tag.offset_x,
    offsetY: tag.offset_y,
    frameNumber: tag.frame_number,
    frameWidth: tag.frame_width,
    frameHeight: tag.frame_height,
    type: tag.type,
  };
};
