<script lang="ts" setup>
import ResponsiveGrid from 'src/components/common/ResponsiveGrid.vue';
import { ANButton, ANDrawer, ANSpinner } from 'src/components/Uikit/Components';
import { IVideo } from 'src/core/Interfaces/IVideo';
import {
  PaginatedResponse,
  PaginationData,
  VideoHttpService,
} from 'src/core/services/Video/VideoHttpService';
import { onMounted, ref } from 'vue';
import { DEFAULT_PAGE_SIZE } from '../common/pagination/Pagination.types';
import { getPoster, getVideoName } from 'src/core/Utils/VideoUtils';
import { useRoute } from 'vue-router';
import { getShortDisplayDate } from 'src/services/utilities/DateUtils';
import { EMIT_EVENTS } from 'src/core/Constants';

const videoHttpService = new VideoHttpService();

withDefaults(
  defineProps<{
    shouldSkipNavigation?: boolean;
    unselectableVideoIds?: string[];
  }>(),
  { shouldSkipNavigation: false, unselectableVideoIds: () => [] },
);

const emit = defineEmits([EMIT_EVENTS.SELECT, EMIT_EVENTS.CLOSE]);
const route = useRoute();

const params = route.params;

const videos = ref<IVideo[]>([]);
const pagination = ref<PaginationData>();
const currentPage = ref(0);
const isVideosLoading = ref<boolean>(false);

onMounted(async () => {
  await getVideos();
});

const getVideos = async (skip = 0, take = DEFAULT_PAGE_SIZE) => {
  isVideosLoading.value = true;
  const data = await videoHttpService.getVideos<PaginatedResponse<IVideo>>(
    skip,
    take,
  );
  videos.value = [...videos.value, ...data.items];
  pagination.value = data.pagination;

  isVideosLoading.value = false;
};

const loadNextPage = async () => {
  currentPage.value += 1;
  await getVideos(DEFAULT_PAGE_SIZE * currentPage.value);
};
</script>

<template>
  <ANDrawer closable @close="emit('close')">
    <template #title> Select video </template>

    <div
      v-if="isVideosLoading"
      class="mt-5 flex h-full items-center justify-center"
    >
      <ANSpinner />
    </div>

    <div v-else class="flex h-full flex-col justify-between">
      <ResponsiveGrid :min-column-width="5" :gap="2" class="-mt-2.5">
        <div v-for="video in videos" :key="video.id" class="relative">
          <component
            :is="shouldSkipNavigation ? 'button' : 'a'"
            :key="video.id"
            :href="shouldSkipNavigation ? null : `/app/videos/${video.id}`"
            :class="{
              'pointer-events-none opacity-50': [
                unselectableVideoIds?.includes(video.id),
                params.videoId === video.id,
              ].some(Boolean),
            }"
            class="flex h-full w-full cursor-pointer flex-col transition-opacity duration-100 ease-in hover:opacity-90"
            @click="shouldSkipNavigation ? emit('select', video) : null"
          >
            <div
              class="relative flex aspect-square h-full w-full flex-grow items-center justify-center overflow-hidden rounded-lg bg-black text-sm text-white shadow-md"
            >
              <img
                :id="video.id"
                :src="getPoster(video)"
                :alt="getVideoName(video.blobName)"
                class="absolute left-0 top-0 z-0 h-full w-full object-cover"
              />
            </div>
            <p class="py-1 text-xs text-stone-400">
              {{ getShortDisplayDate(video.createdAt) }}
            </p>
          </component>
        </div>
      </ResponsiveGrid>

      <ANButton
        v-if="(pagination?.total ?? 0) > videos.length"
        full
        small
        @click="loadNextPage()"
      >
        Load more
      </ANButton>
    </div>
  </ANDrawer>
</template>
