<template>
  <SidebarLayout>
    <GridContainer>
      <div class="dashboard col-span-12 mb-20 flex flex-col">
        <PageHeader>
          Dashboard

          <template #action>
            <div class="flex items-center">
              <VideoUpload />
            </div>
          </template>
        </PageHeader>

        <DashboardNotifications class="mb-8" />
        <DashboardVideos />

        <div class="mt-10 flex items-center justify-between">
          <h3 class="an-dark--text text-xl font-medium capitalize leading-8">
            My Teams
          </h3>

          <a
            v-if="teamsStore.teams.length > 8"
            href="/app/teams"
            class="flex items-center text-sm font-semibold text-gray-900 transition duration-200 ease-in-out hover:text-gray-600"
          >
            See All
          </a>
        </div>

        <div class="mt-4 flex items-center overflow-x-auto">
          <template v-if="teamsStore.teams.length">
            <RouterLink
              v-for="team in teamsStore.teams"
              :key="team.id"
              :to="`/app/teams/${team.id}`"
              class="mr-2 flex h-24 w-24 flex-shrink-0 rounded-lg bg-white p-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-slate-200"
            >
              {{ team.name }}
            </RouterLink>
          </template>

          <div v-else class="w-full text-center">
            <EmptyState>
              <template #illustration>
                <UserGroupIcon class="h-8 w-8 text-gray-300" />
              </template>

              <template #description>
                You haven't invited any athletes to join your team yet
              </template>
            </EmptyState>
          </div>
        </div>

        <div class="mt-10 flex items-center justify-between">
          <h3 class="an-dark--text text-xl font-medium capitalize leading-8">
            My Albums
          </h3>

          <a
            v-if="albums.length === 9"
            href="/app/videos/albums/"
            class="flex items-center text-sm font-semibold text-gray-900 transition duration-200 ease-in-out hover:text-gray-600"
          >
            See All
          </a>
        </div>

        <div class="mt-4 flex items-center overflow-x-auto">
          <VideoAlbums
            v-if="albums.length"
            css-class="w-[100px] mr-2"
            :albums="albums"
            @select="onAlbumSelected"
          />

          <div v-else class="w-full text-center">
            <EmptyState>
              <template #illustration>
                <Squares2X2Icon class="h-8 w-8 text-gray-300" />
              </template>

              <template #description>
                You haven't created any albums yet
              </template>
            </EmptyState>
          </div>
        </div>
      </div>
    </GridContainer>
  </SidebarLayout>
</template>

<script lang="ts" setup>
import { Squares2X2Icon, UserGroupIcon } from '@heroicons/vue/20/solid';
import { API, useEndpoint } from 'src/api/api.endpoints';
import VideoAlbums from 'src/components/Video/VideoAlbums.vue';
import VideoUpload from 'src/components/Video/VideoUpload.vue';
import EmptyState from 'src/components/common/EmptyState.vue';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { APP_ROUTE_NAMES } from 'src/core/AppRoutes';
import { HttpClientFetch } from 'src/core/HttpClient';
import { IMember } from 'src/core/Interfaces/IAthlete';
import { IVideoAlbumEntry } from 'src/core/Interfaces/IVideoAlbum';
import { UserAvatarFile } from 'src/core/models/UserAvatarFile';
import { AlbumsService } from 'src/core/services/Albums/AlbumsService';
import { userAvatarRef } from 'src/core/services/storage-service';
import DashboardVideos from 'src/pages/app/Dashboard/DashboardVideos.vue';
import DashboardNotifications from 'src/pages/app/Dashboard/DashboardNotifications.vue';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useTeamsStore } from 'src/stores/teamsStore';

const albumsService = new AlbumsService();
const router = useRouter();
const teamsStore = useTeamsStore();

const isAthletesLoading = ref<boolean>(false);
const athletes = ref<IMember[]>([]);
const avatars = ref(new Map());
const albums = ref<IVideoAlbumEntry[]>([]);

onMounted(async () => {
  getAthletes();
  getAlbums();
});

const getAlbums = async () => {
  const albumsResponse = await albumsService.getAlbums(4);

  albums.value = albumsResponse.slice(0, 9);
};

const getAthletes = async () => {
  isAthletesLoading.value = true;
  const response = await HttpClientFetch(useEndpoint(API.MY_ATHLETES));

  isAthletesLoading.value = false;

  if (!response.ok) {
    return;
  }

  const { items } = await response.json();

  athletes.value = items.slice(0, 9);
  athletes.value.forEach(({ id, username: displayName }) => {
    avatars.value.set(
      id,
      new UserAvatarFile(userAvatarRef(id), { displayName }),
    );
  });
};

const onAlbumSelected = (key: string) => {
  router.push({
    name: APP_ROUTE_NAMES.VIDEO_ALBUM,
    params: { key },
  });
};
</script>
