import { BaseBffHttpService } from '../BaseBffHttpService';
import {
  GetTeamsResponse,
  GetTeamsResponseSchema,
  TeamDto,
  TeamDtoSchema,
} from './Teams.types';

type CreateTeamRequest = {
  name: string;
  description: string | undefined;
};

export class TeamsHttpService extends BaseBffHttpService {
  basePath = 'teams';

  async list(): Promise<GetTeamsResponse> {
    const response = await this.get(this.basePath);
    const { items } = await response.json();

    const parsedData = GetTeamsResponseSchema.parse({ items });

    return parsedData;
  }

  async getTeam(teamId: string): Promise<TeamDto> {
    const response = await this.get(
      [this.basePath, teamId].join(this.pathSeparator),
    );
    const data = await response.json();

    const parsedData = TeamDtoSchema.parse(data);

    return parsedData;
  }

  create(body: CreateTeamRequest) {
    return this.post<CreateTeamRequest>(this.basePath, body);
  }

  deleteTeam(id: string) {
    return this.delete(`${this.basePath}/${id}`);
  }
}
