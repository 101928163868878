import { defineStore } from 'pinia';
import { TeamDto, TeamsHttpService } from 'src/core/services/Teams';
import { ref } from 'vue';

const teamsHttpService = new TeamsHttpService();

export const useTeamsStore = defineStore('teams', () => {
  const teams = ref<TeamDto[]>([]);

  async function fetchTeams() {
    try {
      const { items } = await teamsHttpService.list();
      teams.value = items;
    } catch (error) {
      console.error('Failed to fetch teams:', error);
    }
  }

  fetchTeams();

  return { teams, fetchTeams };
});
