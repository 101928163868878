<template>
  <SidebarLayout>
    <GridContainer>
      <template v-if="isSetup && !team?.id">
        <div class="col-span-12 mb-20">
          <PageHeader> Team not found {{ team }}</PageHeader>
          <p class="text-lg text-neutral-600">
            The team you are looking for does not exist.
          </p>
          <RouterLink :to="APP_ROUTES.TEAMS" class="mt-4"
            >Go back to teams</RouterLink
          >
        </div>
      </template>
      <template v-else>
        <div class="col-span-12 mb-20">
          <PageHeader>
            {{ team?.name }}
          </PageHeader>

          <div class="rounded-md bg-white p-5">
            <div
              class="mb-4 flex justify-between border-b border-neutral-200 text-lg font-semibold"
            >
              <div class="flex gap-2">
                <button
                  class="border-b-2 p-2"
                  :class="
                    activeTab === 'members'
                      ? 'border-blue-600'
                      : 'border-transparent'
                  "
                  @click="activeTab = 'members'"
                >
                  Team members
                  <div class="badge badge-ghost" v-if="team?.members?.length">
                    {{ team?.members?.length }}
                  </div>
                </button>
                <button
                  v-if="isTeamOwner"
                  class="border-b-2 p-2"
                  :class="
                    activeTab === 'invites'
                      ? 'border-blue-600'
                      : 'border-transparent'
                  "
                  @click="activeTab = 'invites'"
                >
                  Pending invites
                  <div class="badge badge-ghost" v-if="team?.invites?.length">
                    {{ team?.invites?.length }}
                  </div>
                </button>
              </div>
              <div
                class="flex items-center py-2"
                v-if="
                  isTeamOwner &&
                  (team?.members?.length || team?.invites?.length)
                "
              >
                <ANButton
                  dark
                  small
                  class="text-xs"
                  @click="isInviteAthleteModalOpen = true"
                >
                  <UserPlus class="mr-2 h-4 w-4" />
                  Invite member
                </ANButton>
              </div>
            </div>

            <div v-if="activeTab === 'members'">
              <table class="table table-xs" v-if="team?.members?.length">
                <thead>
                  <tr class="text-xs text-neutral-800">
                    <th>Name</th>
                    <th>Member since</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="member in team?.members"
                    :key="member.id"
                    :class="{
                      'hover:cursor-pointer': isTeamOwner,
                    }"
                  >
                    <td class="py-3">
                      {{ member.account.email }}
                      <span
                        v-if="member.account.id === user?.uid"
                        class="badge badge-ghost ml-1 text-xxs font-semibold uppercase"
                      >
                        me
                      </span>
                    </td>
                    <td class="py-3">
                      {{ getShortDisplayDate(member.createdAt) }}
                    </td>
                    <td class="py-3">
                      <ContextCard
                        v-if="isTeamOwner"
                        class="ml-auto"
                        position="right"
                        margin="z-50"
                        @click.prevent
                      >
                        <div
                          @click.prevent
                          class="tooltip cursor-pointer hover:text-blue-600"
                          data-tip="More"
                        >
                          <Ellipsis class="h-4 w-4" />
                        </div>
                        <template #content>
                          <div>
                            <button
                              class="flex w-full items-center rounded-md p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                              type="button"
                              @click.prevent="removeMember(member.id)"
                            >
                              <Trash2 class="mr-3 h-4 w-4" />
                              <span>Remove member</span>
                            </button>
                          </div>
                        </template>
                      </ContextCard>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="mt-10 flex flex-col items-center justify-center gap-5"
              >
                <h2 class="text-lg font-semibold">No members yet</h2>
                <p class="text-center text-lg text-neutral-600">
                  You don't have any members in your team yet.
                  <br />
                  Start building your team now by inviting members.
                </p>
                <ANButton
                  dark
                  class="mt-3 text-xs"
                  @click="isInviteAthleteModalOpen = true"
                >
                  <UserPlus class="mr-2 h-4 w-4" />
                  Invite member
                </ANButton>
              </div>
            </div>

            <div v-if="activeTab === 'invites' && isTeamOwner">
              <table class="table table-xs" v-if="team?.invites?.length">
                <thead>
                  <tr class="text-xs text-neutral-800">
                    <th
                      v-for="(heading, index) in HEADINGS_CONFIG"
                      :key="`${heading}-${index}`"
                    >
                      {{ heading }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invite in team?.invites" :key="invite.id">
                    <td class="py-3">{{ invite.email }}</td>
                    <td class="py-3">
                      <div
                        class="badge text-xxs font-bold uppercase"
                        :class="{
                          'badge-success': invite.status === 'accepted',
                          'badge-error': invite.status === 'rejected',
                          'badge-primary': invite.status === 'pending',
                        }"
                      >
                        {{ invite.status }}
                      </div>
                    </td>
                    <td class="py-3">
                      {{ getShortDisplayDate(invite.createdAt) }}
                    </td>
                    <td class="py-3 text-right">
                      <ContextCard
                        v-if="isTeamOwner"
                        class="ml-auto"
                        position="right"
                        margin="z-50"
                        @click.prevent
                      >
                        <div
                          @click.prevent
                          class="tooltip cursor-pointer hover:text-blue-600"
                          data-tip="More"
                        >
                          <Ellipsis class="h-4 w-4" />
                        </div>
                        <template #content>
                          <div>
                            <button
                              class="flex w-full items-center rounded-md p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                              type="button"
                              @click="removeInvite(invite.id)"
                            >
                              <Trash2 class="mr-3 h-4 w-4" />
                              <span>Remove invite</span>
                            </button>
                          </div>
                        </template>
                      </ContextCard>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-else
                class="mt-10 flex flex-col items-center justify-center gap-5"
              >
                <h2 class="text-lg font-semibold">No pending invites yet</h2>
                <p class="text-center text-lg text-neutral-600">
                  You don't have any pending invites in your team yet.
                  <br />
                  Start building your team now by inviting members.
                </p>
                <ANButton
                  dark
                  class="mt-3 text-xs"
                  @click="isInviteAthleteModalOpen = true"
                >
                  <UserPlus class="mr-2 h-4 w-4" />
                  Invite member
                </ANButton>
              </div>
            </div>
          </div>

          <div class="mt-8 rounded-md bg-white p-5">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-semibold">
                Team videos
                <div class="badge badge-ghost" v-if="totalVideosCount">
                  {{ totalVideosCount }}
                </div>
              </h3>
              <VideoUpload v-if="teamId" :teamId="teamId" />
            </div>
            <div
              class="mt-4 grid grid-cols-3 gap-6 xs:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
              v-if="teamVideos.length"
            >
              <div
                v-for="{ video } in teamVideos"
                :key="video.id"
                class="relative"
              >
                <a
                  :href="getVideoRoute(video)"
                  class="flex cursor-pointer flex-col"
                >
                  <div
                    class="relative flex aspect-square flex-grow items-center justify-center overflow-hidden rounded-lg bg-black text-sm text-white shadow-md"
                  >
                    <img
                      :id="video.id"
                      :src="getPoster(video)"
                      :alt="getVideoName(video.blobName)"
                      class="absolute left-0 top-0 z-0 h-full w-full object-cover"
                    />
                  </div>
                  <p class="py-1 text-xs text-stone-400">
                    {{ getShortDisplayDate(video.createdAt) }}
                  </p>
                </a>
              </div>
            </div>
            <div
              v-else
              class="mt-10 flex flex-col items-center justify-center gap-5"
            >
              <h2 class="text-lg font-semibold">No videos yet</h2>
              <p class="text-center text-lg text-neutral-600">
                Start sharing videos with your team by adding the first video.
              </p>
            </div>
          </div>
        </div>

        <ANModal v-if="isInviteAthleteModalOpen" size="Medium">
          <template #title>Invite new member</template>

          <div>
            <ANInput
              v-model="email"
              type="email"
              placeholder="Email"
              class="mb-4"
            />
          </div>

          <template #action>
            <ANButton
              class="mr-2 w-full"
              @click="isInviteAthleteModalOpen = false"
            >
              Cancel
            </ANButton>
            <ANButton dark class="ml-2 w-full" @click="createInvite">
              Invite
            </ANButton>
          </template>
        </ANModal>
      </template>
    </GridContainer>
  </SidebarLayout>
</template>

<script setup lang="ts">
import { Ellipsis, Trash2, UserPlus } from 'lucide-vue-next';
import ContextCard from 'src/components/common/ContextCard.vue';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { ANButton, ANInput, ANModal } from 'src/components/Uikit/Components';
import VideoUpload, { EVENTS } from 'src/components/Video/VideoUpload.vue';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { APP_ROUTES } from 'src/core/AppRoutes';
import { useEmitter } from 'src/core/EventEmitter';
import { TeamInvitesHttpService } from 'src/core/services/TeamInvites';
import { TeamDto, TeamsHttpService, TeamVideo } from 'src/core/services/Teams';
import { TeamMembersHttpService } from 'src/core/services/Teams/TeamMembersHttpService';
import { TeamVideosHttpService } from 'src/core/services/teamVideos/TeamVideosHttpService';
import {
  getPoster,
  getVideoName,
  getVideoRoute,
} from 'src/core/Utils/VideoUtils';
import { getShortDisplayDate } from 'src/services/utilities/DateUtils';
import { computed, onMounted, ref, watch } from 'vue';
import { RouteParamValue, useRoute } from 'vue-router';

const HEADINGS_CONFIG = ['Email', 'Status', 'Date Invited', ''];
const route = useRoute();
const emitter = useEmitter();
const user = useAppUser();

const teamInvitesHttpService = new TeamInvitesHttpService();
const teamMembersHttpService = new TeamMembersHttpService();
const teamVideosHttpService = new TeamVideosHttpService();
const teamsHttpService = new TeamsHttpService();

const isSetup = ref(false);
const isInviteAthleteModalOpen = ref(false);
const teamId = ref<string | null>(null);
const team = ref<TeamDto>();
const teamVideos = ref<TeamVideo[]>([]);
const email = ref('');
const activeTab = ref<'members' | 'invites'>('members');
const totalVideosCount = ref<number | null>(null);

const isTeamOwner = computed(() => {
  return team.value?.role === 'owner';
});

const setupData = () => {
  if (!teamId.value) {
    return;
  }

  getTeamVideos();
  getTeam();
  isSetup.value = true;
};

onMounted(() => {
  setTeamId(route.params.teamId);
  setupData();
  emitter.on(EVENTS.UPLOAD_FINISHED, () => getTeamVideos());
});

watch(
  () => route.params.teamId,
  (newId) => {
    setTeamId(newId);
    setupData();
  },
);

const setTeamId = async (newId: RouteParamValue | RouteParamValue[]) => {
  teamId.value = Array.isArray(newId) ? newId[0] : newId;
};

const getTeam = async () => {
  if (!teamId.value) {
    return;
  }

  team.value = await teamsHttpService.getTeam(teamId.value);
};

const getTeamVideos = async () => {
  if (!teamId.value) {
    return;
  }

  const response = await teamVideosHttpService.list(teamId.value);

  if (!response) {
    return;
  }

  teamVideos.value = response.items.map((video) => ({
    ...video,
    createdAt: getShortDisplayDate(video.createdAt),
  }));
  totalVideosCount.value = response.pagination.total;
};

const createInvite = () => {
  if (!teamId.value) {
    return;
  }

  teamInvitesHttpService
    .create(teamId.value, {
      email: email.value,
    })
    .then(() => {
      isInviteAthleteModalOpen.value = false;
      email.value = '';
      getTeam();
    });
};

const removeInvite = (inviteId: string) => {
  teamInvitesHttpService.remove(inviteId).then(() => {
    getTeam();
  });
};

const removeMember = (memberId: string) => {
  if (!teamId.value) {
    return;
  }

  teamMembersHttpService
    .deleteTeamMember({
      teamId: teamId.value,
      memberId,
    })
    .then(() => {
      getTeam();
    });
};
</script>
