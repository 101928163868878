import { BaseBffHttpService } from '../BaseBffHttpService';
import { GetTeamVideosResponseSchema } from '../Teams';

export class TeamVideosHttpService extends BaseBffHttpService {
  basePath = 'team';
  #getBasePath(teamId: string) {
    return `${this.basePath}/${teamId}/videos`;
  }

  async list(teamId: string) {
    const response = await this.get(this.#getBasePath(teamId));
    const data = await response.json();

    const parsedData = GetTeamVideosResponseSchema.parse(data);

    return parsedData;
  }
}
