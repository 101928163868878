<template>
  <div
    class="absolute left-0 top-0 h-full w-full"
    :class="{ 'z-10': activeMode === DrawingMode.LINE }"
  >
    <div class="relative flex h-full w-full items-center justify-center">
      <canvas ref="canvasElement" class="canvas" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { COLORS } from 'src/core/Constants';
import { useEmitter } from 'src/core/EventEmitter';
import { CANVAS_DRAWING_EVENTS } from 'src/core/Events';
import {
  DrawingMode,
  IDrawingsCanvasDimensions,
  IDrawingsContainer,
  useClearPath,
  useDrawingsContainer,
} from 'src/services/DrawingService';
import { getElementDimensionInfo } from 'src/services/utilities/HtmlElementUtilities';
import { onMounted, onUnmounted, ref } from 'vue';

const isTouchCapable = 'ontouchstart' in document.documentElement;

const props = defineProps<{
  videoEl?: HTMLVideoElement;
}>();

let path = useClearPath();
let ctx: CanvasRenderingContext2D;
let drawingsContainer: IDrawingsContainer;
let canvasDimensions = { height: 0, width: 0 };
let canvasOffsets = { x: 0, y: 0 };

const canvasElement = ref<HTMLCanvasElement>();
const isDrawing = ref(false);
const hasDrawn = ref(false);
const strokeStyle = ref(COLORS.pink);
const lineThickness = ref(2);
const emitter = useEmitter();
const activeMode = ref(DrawingMode.LINE);

onMounted(() => {
  if (!canvasElement.value) {
    return;
  }

  registerEmitterEvents();
  setCanvasDimensions();

  const canvasContext = canvasElement.value.getContext('2d');

  if (!canvasContext) {
    return;
  }

  ctx = canvasContext;
  drawingsContainer = useDrawingsContainer(canvasContext);
  subscribe();
  initMediaQueryListWatcher();
});

onUnmounted(() => {
  unsubscribe();

  const { LINE, MODE_CHANGE } = CANVAS_DRAWING_EVENTS;

  [LINE.CLEAR, LINE.UNDO, LINE.OPTIONS_CHANGED, MODE_CHANGE].forEach((type) =>
    emitter.off(type),
  );
});

const updateListeners = (mode: DrawingMode) => {
  if (mode === DrawingMode.LINE) {
    subscribe();
  } else {
    unsubscribe();
  }
};

const subscribe = () => {
  if (!canvasElement?.value) {
    return;
  }

  if (isTouchCapable) {
    canvasElement.value.addEventListener('touchstart', touchstart);
    canvasElement.value.addEventListener('touchmove', touchmove);
    canvasElement.value.addEventListener('touchend', touchend);
  } else {
    canvasElement.value.addEventListener('pointerdown', mousedown);
    canvasElement.value.addEventListener('pointermove', mousemove);
    canvasElement.value.addEventListener('pointerup', mouseup);
  }
};

const unsubscribe = () => {
  if (!canvasElement.value) {
    return;
  }

  if (isTouchCapable) {
    canvasElement.value.removeEventListener('touchstart', touchstart);
    canvasElement.value.removeEventListener('touchmove', touchmove);
    canvasElement.value.removeEventListener('touchend', touchend);
  } else {
    canvasElement.value.removeEventListener('pointerdown', mousedown);
    canvasElement.value.removeEventListener('pointermove', mousemove);
    canvasElement.value.removeEventListener('pointerup', mouseup);
  }
};

const registerEmitterEvents = () => {
  const { LINE, MODE_CHANGE } = CANVAS_DRAWING_EVENTS;

  emitter.on(LINE.CLEAR, clearCanvas);
  emitter.on(LINE.UNDO, undoDrawing);
  emitter.on(LINE.OPTIONS_CHANGED, (options: unknown) => {
    updateDrawing(options as { strokeStyle: string; thickness: number });
  });
  emitter.on(MODE_CHANGE, (mode: unknown) => {
    activeMode.value = mode as DrawingMode;
    updateListeners(mode as DrawingMode);
  });
};

const setCanvasDimensions = () => {
  const { height, width, x, y } = getElementDimensionInfo(props.videoEl);

  canvasDimensions = { height, width };
  canvasOffsets = { x, y };

  if (canvasElement.value) {
    canvasElement.value.height = height;
    canvasElement.value.width = width;
  }
};

const touchstart = (e: TouchEvent) => {
  e.preventDefault();
  const { pageX = 0, pageY = 0 } = e.touches[0];
  path.x1 = pageX;
  path.y1 = pageY;
  isDrawing.value = true;
  hasDrawn.value = false;
};

const touchmove = (e: TouchEvent) => {
  e.preventDefault();
  if (!isDrawing.value) {
    return;
  }

  hasDrawn.value = true;
  const { pageX = 0, pageY = 0 } = e.touches[0];
  path.x2 = pageX;
  path.y2 = pageY;
  clearDrawing();
  drawPath(getOffsetAdjustedPath(path), strokeStyle.value, canvasDimensions);
  drawAllPaths();
};

const touchend = (e: TouchEvent) => {
  e.preventDefault();

  if (!isDrawing.value || !hasDrawn.value) {
    return;
  }

  isDrawing.value = false;
  const { x1, y1, x2, y2 } = getOffsetAdjustedPath(path);
  drawingsContainer.addPath(
    [
      [x1, y1],
      [x2, y2],
    ],
    strokeStyle.value,
    canvasDimensions,
  );
  path = useClearPath();
};

const mousedown = (e: MouseEvent) => {
  const { offsetX, offsetY } = e;
  path.x1 = offsetX;
  path.y1 = offsetY;
  isDrawing.value = true;
};

const mousemove = (e: MouseEvent) => {
  e.preventDefault();
  if (!isDrawing.value) {
    return;
  }

  hasDrawn.value = true;
  const { offsetX, offsetY } = e;
  path.x2 = offsetX;
  path.y2 = offsetY;
  clearDrawing();
  drawPath(path, strokeStyle.value, canvasDimensions);
  drawAllPaths();
};

const mouseup = (e: MouseEvent) => {
  e.preventDefault();
  if (!isDrawing.value || !hasDrawn.value) {
    return;
  }

  isDrawing.value = false;
  const { x1, y1, x2, y2 } = path;
  drawingsContainer.addPath(
    [
      [x1, y1],
      [x2, y2],
    ],
    strokeStyle.value,
    canvasDimensions,
  );
  path = useClearPath();
};

const drawAllPaths = () => {
  const paths = drawingsContainer.getPaths();
  paths.forEach(({ points, strokeStyle, canvasDimensions }) => {
    const [[x1, y1], [x2, y2]] = points;
    drawPath({ x1, y1, x2, y2 }, strokeStyle, canvasDimensions);
  });
};

const drawPath = (
  path: { x1: number; y1: number; x2: number; y2: number },
  strokeStyle: string,
  pathsCanvasDimensions: IDrawingsCanvasDimensions,
) => {
  const { x1, y1, x2, y2 } = path;

  const xRatio = canvasDimensions.width / pathsCanvasDimensions.width;
  const yRatio = canvasDimensions.height / pathsCanvasDimensions.height;

  ctx.beginPath();
  ctx.strokeStyle = strokeStyle || 'red';
  ctx.lineWidth = lineThickness.value;
  ctx.moveTo(x1 * xRatio, y1 * yRatio);
  ctx.lineTo(x2 * xRatio, y2 * yRatio);
  ctx.stroke();
  ctx.closePath();
};

const clearDrawing = () => {
  ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
};

const clearCanvas = () => {
  drawingsContainer.clearPaths();
  clearDrawing();
};

const undoDrawing = () => {
  drawingsContainer.clearLastPath();
  clearDrawing();
  drawAllPaths();
};

const updateDrawing = ({
  strokeStyle,
  thickness,
}: {
  strokeStyle: string;
  thickness: number;
}) => {
  setStrokeStyle(strokeStyle);
  updateLineThickness(thickness);
};

const setStrokeStyle = (color: string) => {
  strokeStyle.value = color;
};

const updateLineThickness = (thickness: number) => {
  lineThickness.value = thickness;

  clearDrawing();
  drawAllPaths();
};

const initMediaQueryListWatcher = () => {
  const mql = window.matchMedia(
    '(orientation: landscape) and (pointer: coarse) and (hover: none)',
  );

  mql.addEventListener('change', () => {
    clearDrawing();
    setCanvasDimensions();
    drawAllPaths();
  });
};

const getOffsetAdjustedPath = (path: {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}) => {
  const x1 = path.x1 - canvasOffsets.x;
  const x2 = path.x2 - canvasOffsets.x;
  const y1 = path.y1 - canvasOffsets.y;
  const y2 = path.y2 - canvasOffsets.y;

  return {
    x1,
    y1,
    x2,
    y2,
  };
};
</script>

<style scoped>
.canvas {
  touch-action: none;
}
</style>
