<script lang="ts" setup>
import { APP_ROUTES } from 'src/core/AppRoutes';
import { AccountsHttpService } from 'src/core/services/accounts/AccountsHttpService';
import { computed, onMounted, ref } from 'vue';

const accountHttpService = new AccountsHttpService();

const account = ref();

onMounted(() => {
  getSelfAccount();
});

const getSelfAccount = async () => {
  const response = await accountHttpService.getSelfAccount();
  account.value = response.ok ? await response.json() : {};
};

const invites = computed(() => {
  return account.value?.teamInvites ?? [];
});
</script>
<template>
  <div
    role="alert"
    class="alert rounded-xl border border-blue-200 bg-blue-50 shadow-sm"
    v-if="invites.length"
  >
    <p class="shrink-0 stroke-info text-2xl">🎉</p>
    <div>
      <h3 class="font-bold">New invitation!</h3>
      <div class="text-xs">
        You have {{ invites.length }} pending team invitation
      </div>
    </div>
    <RouterLink
      :to="APP_ROUTES.TEAMS"
      class="btn btn-sm border-blue-300 bg-blue-100 text-gray-800 transition duration-300 ease-in-out hover:border-blue-400 hover:bg-blue-200"
    >
      See
    </RouterLink>
  </div>
</template>
