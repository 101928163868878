<template>
  <div class="relative">
    <div
      v-if="imagesLoadingProgress < 100"
      class="fixed left-0 top-1/2 z-10 flex w-full -translate-y-1/2 justify-center"
    >
      <div
        class="rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-1"
      >
        <div
          class="flex aspect-square h-16 w-16 items-center justify-center rounded-full bg-slate-800 tabular-nums text-white"
        >
          {{ imagesLoadingProgress.toFixed() }}%
        </div>
      </div>
    </div>
    <div
      v-if="frameLoadingErrors === video.framesCount"
      class="fixed left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 text-center text-red-200"
    >
      There was an error loading the video. Please refresh the page or try
      uploading the video again.
    </div>
    <div
      v-if="frameLoadingErrors < video.framesCount"
      ref="frameScene"
      class="flex"
      :class="[
        imagesLoadingProgress === 100 ? 'visible' : 'invisible',
        orientation === MediaOrientation.Portrait
          ? 'aspect-[9/16] max-h-screen'
          : 'max-w-screen aspect-video',
      ]"
    >
      <canvas
        id="frameCanvas"
        class="absolute max-h-full max-w-full"
        width="0"
        height="0"
      ></canvas>
      <img
        v-for="index in video.framesCount"
        :key="index"
        :src="getFrameUrl(index, video.blobDirectory)"
        :alt="`Frame No.${index}`"
        :class="[currentFrame === index ? 'block' : 'hidden']"
        @load="loadFrameHandler"
        @error="loadFrameErrorHandler"
      />
    </div>
    <div
      v-if="imagesLoadingProgress === 100"
      class="fixed right-2 top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-white/40 p-2"
    >
      <ColorPicker v-model="lineColor" @update:model-value="drawCurrentFrame" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { MediaOrientation } from 'src/core/Common.types';
import {
  COLORS,
  ColorName,
  headKeypoints,
  skeletonKeypoints,
} from 'src/core/Constants';
import { IVideo } from 'src/core/Interfaces/IVideo';
import { getFrameUrl } from 'src/core/Utils/VideoFrameUtils';
import {
  Keypoint,
  Keypoints,
  VideoKeypointsHttpService,
} from 'src/core/services/Video/VideoKeypointsHttpService';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import ColorPicker from '../common/ColorPicker.vue';

const videoKeypointsHttpService = new VideoKeypointsHttpService();

const frameScene = ref<HTMLDivElement | null>(null);
const frameSceneResizeObserver = ref<ResizeObserver>();
const loadedFrames = ref(0);
const frameLoadingErrors = ref(0);
const keypoints = ref<Partial<Keypoints>>({});
const coordinateRatio = ref(1);
const lineColor = ref<string>();

let canvasElement: HTMLCanvasElement | null;
let canvasContext: CanvasRenderingContext2D | null;

const props = withDefaults(
  defineProps<{
    video: IVideo;
    currentTime: number;
    orientation?: MediaOrientation;
  }>(),
  {
    currentTime: 0,
    orientation: MediaOrientation.Portrait,
  },
);

onMounted(async () => {
  keypoints.value = await videoKeypointsHttpService.getKeypoints(props.video);
  canvasElement = document.querySelector<HTMLCanvasElement>('#frameCanvas');
  canvasContext = canvasElement?.getContext('2d') ?? null;

  frameSceneResizeObserver.value = new ResizeObserver(() => {
    if (canvasElement) {
      setCanvasSize(canvasElement);
      drawCurrentFrame();
    }
  });

  if (frameScene.value) {
    frameSceneResizeObserver.value.observe(frameScene.value);
  }
});

onUnmounted(() => {
  frameSceneResizeObserver?.value?.disconnect();
});

const imagesLoadingProgress = computed(() => {
  return (loadedFrames.value / props.video.framesCount) * 100;
});

const currentFrame = computed(() => {
  if (!props.currentTime) {
    return 1;
  }

  return Math.floor(
    (props.currentTime / props.video.duration) * props.video.framesCount,
  );
});

const currentPoseKeypoints = computed(() => {
  const frameKey = `f${currentFrame.value.toString().padStart(4, '0')}`;

  if (!keypoints.value.frames) {
    return;
  }

  return keypoints.value.frames[frameKey]?.pose?.keypoints;
});

const selectedColor = computed(() => {
  return lineColor.value ?? COLORS[ColorName.WHITE];
});

watch(currentPoseKeypoints, () => {
  drawCurrentFrame();
});

const drawCurrentFrame = () => {
  if (!canvasContext) {
    return;
  }

  canvasContext.clearRect(
    0,
    0,
    props.video.width * coordinateRatio.value,
    props.video.height * coordinateRatio.value,
  );

  const poseKeypoints = new Map<string, Pick<Keypoint, 'x' | 'y'>>();

  (currentPoseKeypoints.value ?? []).forEach(({ x, y, name }) => {
    if (headKeypoints.includes(name)) {
      return;
    }

    canvasContext!.fillStyle = selectedColor.value;
    canvasContext!.beginPath();
    canvasContext!.arc(
      x * coordinateRatio.value,
      y * coordinateRatio.value,
      2,
      0,
      2 * Math.PI,
      true,
    );
    canvasContext!.closePath();
    canvasContext!.fill();

    poseKeypoints.set(name, { x, y });
  });

  skeletonKeypoints.forEach((moveNetKeypoints) => {
    canvasContext?.beginPath();
    (canvasContext as CanvasRenderingContext2D).strokeStyle =
      selectedColor.value;
    (canvasContext as CanvasRenderingContext2D).lineWidth = 2;

    moveNetKeypoints.forEach((moveNetKeypoint, index) => {
      const keypoint = poseKeypoints.get(moveNetKeypoint);

      if (!keypoint) {
        return;
      }

      const [x, y] = [
        keypoint.x * coordinateRatio.value,
        keypoint.y * coordinateRatio.value,
      ];

      if (index === 0) {
        canvasContext?.moveTo(x, y);

        return;
      }

      canvasContext?.lineTo(x, y);
    });

    canvasContext?.stroke();
    canvasContext?.closePath();
  });
};

const loadFrameHandler = () => {
  loadedFrames.value++;
};

const loadFrameErrorHandler = () => {
  loadedFrames.value++;
  frameLoadingErrors.value++;
};

const setCanvasSize = (canvasNode: HTMLCanvasElement) => {
  const width =
    props.orientation === MediaOrientation.Portrait
      ? props.video.height
      : props.video.width;

  const height =
    props.orientation === MediaOrientation.Portrait
      ? props.video.width
      : props.video.height;

  coordinateRatio.value =
    window.innerWidth > width ? 1 : window.innerWidth / width;

  /**
   * Set canvas size.
   * We use frameScene size because it's equal to a video frame size which
   * was used to detect pose's keypoints. This is a UI fix for videos uploaded
   * between 2024-01-21 21:45:51.037 +0200 and 2024-01-26 01:05:19.679 +0200.
   * Between these dates we run a keyframe extraction and keypoints inference
   * on frames that were 75% size of source video.
   * The canvas element size is based on the source video size.
   * This fixes the issue.
   */
  const canvasWidth =
    frameScene.value?.clientWidth ?? width * coordinateRatio.value;
  const canvasHeight =
    frameScene.value?.clientHeight ?? height * coordinateRatio.value;

  canvasNode?.setAttribute('width', String(canvasWidth));
  canvasNode?.setAttribute('height', String(canvasHeight));
};
</script>
