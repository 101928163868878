<template>
  <RegistrationLayout class="an-gradient-bg relative">
    <h1 class="mb-9 mt-4 px-4 text-center text-2xl font-bold text-white">
      Sign up to become a member
    </h1>

    <div class="rounded-md bg-white p-12">
      <form method="post">
        <ANInput
          v-model="input.email"
          label="Email"
          placeholder="Enter Your Email"
          @focus="clearError"
        />

        <ANInput
          v-model="input.password"
          class="mt-6"
          type="password"
          label="Password"
          placeholder="Set Your Password"
          @focus="clearError"
        />

        <ErrorNotification
          v-if="error"
          class="my-3 mt-4 block rounded-lg bg-white p-2 text-red-500"
        >
          <p class="text-sm text-red-500">
            Couldn't create an account. Please try again or get in touch with us
            via
            <a
              class="inline-flex whitespace-nowrap text-blue-500 transition duration-300 hover:text-primary"
              href="mailto:support@athleticnext.com"
            >
              support@athleticnext.com
            </a>
          </p>
        </ErrorNotification>

        <ANButton
          class="mt-6"
          :disabled="!canSubmit && !isRegistrationInProgress"
          dark
          full
          @click="register"
        >
          <ANSpinner v-if="isRegistrationInProgress" light />
          <template v-else>Create account</template>
        </ANButton>

        <p class="mt-4 text-center text-sm text-primary">
          Already have an account?
          <a
            href="/login"
            class="font-semibold text-gray-900 transition duration-300 hover:text-orange-500 hover:underline"
          >
            Sign in
          </a>
        </p>

        <p class="mt-12 text-center text-xs text-primary sm:mt-8">
          By creating an account, you agree to our
          <router-link
            :to="PUBLIC_ROUTES.TERMS_AND_CONDITIONS"
            class="font-semibold text-gray-900 transition duration-200 hover:text-orange-500 hover:underline"
          >
            Terms of Service
          </router-link>
          <span>&nbsp;and&nbsp;</span>
          <router-link
            :to="PUBLIC_ROUTES.PRIVACY_POLICY"
            class="font-semibold text-gray-900 transition duration-200 hover:text-orange-500 hover:underline"
          >
            Privacy Policy.
          </router-link>
        </p>
      </form>
    </div>
  </RegistrationLayout>
</template>

<script setup lang="ts">
import { signInWithCustomToken } from '@firebase/auth';
import { ANButton, ANInput, ANSpinner } from 'src/components/Uikit/Components';
import ErrorNotification from 'src/components/common/ErrorNotification.vue';
import RegistrationLayout from 'src/components/layout/RegistrationLayout.vue';
import { auth } from 'src/core/3rdParty/Firebase';
import { APP_ROUTES, PUBLIC_ROUTES } from 'src/core/AppRoutes';
import { logUserLogin } from 'src/core/services/AnalyticsService';
import { AccountsHttpService } from 'src/core/services/accounts/AccountsHttpService';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const accountsHttpService: AccountsHttpService = new AccountsHttpService();

const router = useRouter();
const route = useRoute();

const error = ref(false);
const input = ref({
  email: '',
  password: '',
  inviteToken: '',
});

onMounted(() => {
  const { t } = route.query;

  input.value.inviteToken = (t as string) || '';
});

const isRegistrationInProgress = ref(false);

const canSubmit = computed(() => !!input.value.email && !!input.value.password);

const register = async () => {
  clearError();

  if (!canSubmit.value) {
    return;
  }

  isRegistrationInProgress.value = true;

  const { token } = await accountsHttpService.create(input.value).catch(() => {
    isRegistrationInProgress.value = false;
    error.value = true;

    return { token: null };
  });

  if (!token) {
    return;
  }

  const user = await signInWithCustomToken(auth, token).catch(() => {
    isRegistrationInProgress.value = false;
    error.value = true;
  });

  if (!user) {
    error.value = true;

    return;
  }

  logUserLogin();
  router.push(APP_ROUTES.DASHBOARD);
};

const clearError = () => (error.value = false);
</script>
