<template>
  <div class="comments-wrapper h-full px-4 pb-2">
    <div
      v-if="requestInProgress"
      class="an-min-h-full flex items-center justify-center"
    >
      <ANSpinner size="w-10 h-10" />
    </div>

    <div v-else class="comments overflow-y-auto pb-16 md:pb-24">
      <div
        v-if="!comments.length"
        class="flex h-full flex-col items-center justify-center"
      >
        <ChatBubbleBottomCenterTextIcon class="h-12 w-12 text-gray-300" />
        <span class="mt-2 text-lg font-light text-gray-300">
          Start commenting...
        </span>
      </div>

      <div
        v-for="(comment, index) in comments"
        :key="index"
        class="flex"
        :class="{ 'justify-end': !isCoachComment(comment.authorId) }"
      >
        <div
          class="relative mb-4 flex justify-between overflow-hidden rounded-2xl p-4 py-3"
          :class="[
            isCoachComment(comment.authorId)
              ? 'flex-row-reverse rounded-tl-none bg-blue-300'
              : 'order-reverse rounded-tr-none bg-slate-300',
            comment.mediaType ? 'voice-comment' : 'text-comment',
          ]"
        >
          <section
            class="w-full"
            :class="isCoachComment(comment.authorId) ? 'ml-4' : 'mr-4'"
          >
            <AudioPlayer
              v-if="comment.mediaUrl"
              :player-key="String(index)"
              :audio-url="comment.mediaUrl"
            />

            <div v-else class="flex items-center justify-between">
              <span class="text-xs font-semibold">
                {{
                  `${comment?.authorFirstName || ''} 
                  ${comment?.authorLastName || ''}`
                }}
              </span>
            </div>
            <span class="text-sm font-light">
              {{ comment.content }}
            </span>
          </section>

          <div
            class="flex"
            :class="[
              isCoachComment(comment.authorId)
                ? 'sm:justify-center'
                : 'justify-end',
              { 'items-center': comment.mediaUrl },
            ]"
          >
            <UserAvatar
              :user-avatar-file="userAvatar.get(comment.authorId)"
              size="h-8 w-8"
              text-class="bg-black text-white"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="sticky bottom-0 left-0 z-10 flex h-16 w-full items-center justify-center border-t border-gray-200 bg-white md:h-24"
    >
      <div class="flex w-full items-center justify-center">
        <div
          v-if="
            activeCommentType === VideoCommentsTabs.Text ||
            activeCommentType === VideoCommentsTabs.Default
          "
          class="relative flex w-full items-center"
        >
          <input
            v-model="activeComment.content"
            placeholder="Add a comment..."
            class="h-12 w-full rounded-2xl border border-gray-400 py-1 pl-4 pr-12"
            @input="setActiveCommentType(VideoCommentsTabs.Text)"
            @blur="handleInputStateChange"
          />
        </div>

        <div class="ml-2">
          <PaperAirplaneIcon
            v-if="activeCommentType === VideoCommentsTabs.Text"
            class="h-6 w-6 cursor-pointer text-primary hover:text-primary-dark"
            @click="saveTextComment()"
          />

          <MicrophoneIcon
            v-else-if="activeCommentType === VideoCommentsTabs.Default"
            class="h-6 w-6 cursor-pointer text-primary hover:text-primary-dark"
            @click="setActiveCommentType(VideoCommentsTabs.Voice)"
          />
        </div>

        <VoiceCommentRecorder
          v-show="activeCommentType === VideoCommentsTabs.Voice"
          :video-id="videoId"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommentsEndpoint } from 'src/api/api.endpoints';
import { HttpClientFetch } from 'src/core/HttpClient';
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { VideoCommentsTabs } from 'src/core/Constants';
import { IVideoComment } from 'src/core/Interfaces/IVideo';
import { ref, onMounted } from 'vue';
import {
  PaperAirplaneIcon,
  ChatBubbleBottomCenterTextIcon,
  MicrophoneIcon,
} from '@heroicons/vue/20/solid';
import { ANSpinner } from 'src/components/Uikit/Components';
import VoiceCommentRecorder from 'src/components/Video/VoiceCommentRecorder.vue';
import { useEmitter } from 'src/core/EventEmitter';
import {
  VOICE_COMMENT_ACTION,
  VOICE_VISUALIZATION_EVENT,
} from 'src/core/Events';
import { userAvatarRef } from 'src/core/services/storage-service';
import { postComment } from 'src/core/services/CommentsHttpService';
import AudioPlayer from 'src/components/Audio/AudioPlayer.vue';
import UserAvatar from 'src/components/common/UserAvatar.vue';
import { UserAvatarFile } from 'src/core/models/UserAvatarFile';

const props = defineProps<{
  videoTime?: number;
  videoId: string;
}>();

const appUser = useAppUser();
const emitter = useEmitter();

const comments = ref<IVideoComment[]>([]);
const requestInProgress = ref<boolean>(false);
const activeCommentType = ref<VideoCommentsTabs>(VideoCommentsTabs.Default);
const userAvatar: Map<string, UserAvatarFile> = new Map();

const activeComment = ref<Partial<IVideoComment>>({
  content: '',
  time: null,
});

onMounted(async () => {
  await getComments();

  emitter.on(VOICE_COMMENT_ACTION.STOP_RECORDING, () =>
    setActiveCommentType(VideoCommentsTabs.Default),
  );
  emitter.on(VOICE_COMMENT_ACTION.GET_COMMENTS, () => getComments());
  emitter.on(VOICE_COMMENT_ACTION.UPLOAD, () => {
    requestInProgress.value = true;
  });

  if (!comments.value.length) {
    return;
  }

  await setUserAvatar();
});

const getUserAvatar = async ({
  authorId,
  authorFirstName,
}: {
  authorId: string;
  authorFirstName: string;
}) => {
  const displayName =
    authorId === appUser?.uid
      ? (appUser.displayName ?? appUser.email ?? authorFirstName)
      : authorFirstName;

  return new UserAvatarFile(userAvatarRef(authorId), {
    displayName,
  });
};

const setUserAvatar = async () => {
  requestInProgress.value = true;

  const authors = new Map();
  comments.value.forEach((author) => authors.set(author.authorId, author));

  const [authorOne, authorTwo] = [...authors.values()];

  if (authorOne) {
    userAvatar.set(authorOne.authorId, await getUserAvatar(authorOne));
  }

  if (authorTwo) {
    userAvatar.set(authorTwo.authorId, await getUserAvatar(authorTwo));
  }

  requestInProgress.value = false;
};

const getComments = async () => {
  requestInProgress.value = true;

  const commentsResponse = await HttpClientFetch(
    useCommentsEndpoint(props.videoId),
  );

  if (!commentsResponse.ok) {
    requestInProgress.value = false;

    return;
  }

  comments.value = await commentsResponse.json();

  if (comments.value.some(({ mediaUrl }) => !!mediaUrl)) {
    emitter.emit(VOICE_VISUALIZATION_EVENT.DRAW);
  }

  requestInProgress.value = false;
};

const saveTextComment = async () => {
  if (!activeComment.value.content?.length) {
    return;
  }

  const formData = new FormData();

  formData.append('Content', activeComment.value.content);
  formData.append('Time', '0.00');

  const response = await postComment(props.videoId, formData);

  if (!response.ok) {
    return;
  }

  getComments();
  resetComment();
};

const resetComment = () => {
  activeComment.value.content = '';
  activeComment.value.time = null;
  handleInputStateChange();
};

const isCoachComment = (authorId: string | null) => {
  if (!appUser || !authorId) {
    return false;
  }

  return appUser.uid === authorId;
};

const setActiveCommentType = (type: VideoCommentsTabs) => {
  if (type === activeCommentType.value) {
    return;
  }

  activeCommentType.value = type;

  if (type !== VideoCommentsTabs.Voice) {
    return;
  }

  emitter.emit(VOICE_COMMENT_ACTION.START_RECORDING);
};

const handleInputStateChange = () => {
  if (activeComment.value.content?.length) {
    return;
  }

  activeCommentType.value = VideoCommentsTabs.Default;
};
</script>

<style scoped>
.comments {
  height: 90%;
}

::placeholder {
  font-weight: 400;
  color: rgb(152, 152, 152);
  padding: 0, 0.5rem;
  font-size: 14px;
}

.voice-comment {
  max-width: 90%;
  min-width: 90%;
}

.text-comment {
  min-width: auto;
  max-width: 90%;
}
</style>
