import { z, ZodType } from 'zod';
import { IVideo } from '../../Interfaces/IVideo';

export type TeamMemberDetails = {
  id: string;
  email: string;
  status: string;
  createdAt: Date;
};

export type TeamMemberDto = {
  id: string;
  createdAt: Date;
  accountId: string;
  email: string;
};

const AccountSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const InviteSchema = z.object({
  id: z.string(),
  email: z.string(),
  status: z.string(),
  teamId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const MemberSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  account: AccountSchema,
});

const TeamDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
  invites: z.array(InviteSchema),
  members: z.array(MemberSchema),
  videoCount: z.number(),
  role: z.enum(['owner', 'member']),
});

const TeamVideoSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  video: z.object({
    blobDirectory: z.string(),
    blobName: z.string(),
    blobUrl: z.string().nullable(),
    createdAt: z.string(),
    duration: z.number(),
    frameRate: z.string().nullable(),
    framesCount: z.number(),
    hasFilmstrip: z.boolean(),
    hasKeypoints: z.boolean(),
    height: z.number(),
    id: z.string(),
    publicId: z.string().nullable(),
    userId: z.string(),
    userUid: z.string(),
    width: z.number(),
  }) satisfies ZodType<IVideo>,
});

export type TeamDto = z.infer<typeof TeamDtoSchema>;

const GetTeamsResponseSchema = z.object({ items: z.array(TeamDtoSchema) });
type GetTeamsResponse = z.infer<typeof GetTeamsResponseSchema>;

const GetTeamVideosResponseSchema = z.object({
  items: z.array(TeamVideoSchema),
  pagination: z.object({
    total: z.number(),
    take: z.number().nullable(),
    skip: z.number().nullable(),
  }),
});
type GetTeamVideosResponse = z.infer<typeof GetTeamVideosResponseSchema>;
type TeamVideo = z.infer<typeof TeamVideoSchema>;

export {
  GetTeamsResponseSchema,
  GetTeamVideosResponseSchema,
  TeamDtoSchema,
  type GetTeamsResponse,
  type GetTeamVideosResponse,
  type TeamVideo
};

