<script setup lang="ts">
import { Users } from 'lucide-vue-next';
import GridContainer from 'src/components/common/GridContainer.vue';
import PageHeader from 'src/components/common/PageHeader.vue';
import SidebarLayout from 'src/components/layout/SidebarLayout.vue';
import { ANButton, ANInput, ANModal } from 'src/components/Uikit/Components';
import { TeamsHttpService } from 'src/core/services/Teams';
import {
  Invite,
  TeamInvitesHttpService,
} from 'src/core/services/Teams/TeamInvitesHttpService';
import { TeamMembersHttpService } from 'src/core/services/Teams/TeamMembersHttpService';
import { useTeamsStore } from 'src/stores/teamsStore';
import { onMounted, ref } from 'vue';
import TeamCard from './components/TeamCard.vue';

const teamsHttpService = new TeamsHttpService();
const teamInvitesHttpService = new TeamInvitesHttpService();
const teamMembersHttpService = new TeamMembersHttpService();

const isCreateTeamModalOpen = ref(false);
const isUpdatingInvite = ref(false);

const teamInvites = ref<Invite[]>([]);
const teamName = ref('');
const teamDescription = ref('');

const teamsStore = useTeamsStore();

// TODO: On mounted and accepting a team invite we need to fetch the current user team memberships
// to display the teams the user is member of
onMounted(() => {
  getTeamInvites();
});

const getTeams = async () => {
  teamsStore.fetchTeams();
};

const getTeamInvites = async () => {
  teamInvites.value = (await teamInvitesHttpService.getSelfTeamInvites()).items;
};

const openCreateTeamModal = () => {
  teamName.value = '';
  teamDescription.value = '';
  isCreateTeamModalOpen.value = true;
};

const createTeam = async () => {
  await teamsHttpService.create({
    name: teamName.value,
    description: teamDescription.value,
  });
  getTeams();

  isCreateTeamModalOpen.value = false;
};

const deleteTeam = async (teamId: string) => {
  await teamsHttpService.deleteTeam(teamId);
  getTeams();
};

const leaveTeam = async (teamId: string) => {
  await teamMembersHttpService.deleteSelfTeamMember(teamId);
  getTeams();
};

const updateInvite = async (inviteId: string, action: 'accept' | 'reject') => {
  if (isUpdatingInvite.value) {
    return;
  }

  isUpdatingInvite.value = true;
  await teamMembersHttpService.updateSelfTeamInvite(inviteId, { action });
  getTeams();
  getTeamInvites();
  isUpdatingInvite.value = false;
};
</script>

<template>
  <SidebarLayout>
    <GridContainer>
      <div class="col-span-12 mb-20">
        <PageHeader>
          My Teams

          <template #action>
            <ANButton dark class="text-xs" @click="openCreateTeamModal">
              <Users class="mr-2 h-4 w-4" />
              New Team
            </ANButton>
          </template>
        </PageHeader>

        <!-- TODO: Improve the UI/UX of team invite cards -->
        <div v-if="teamInvites.length">
          <h2 class="text-lg font-semibold">Your pending invitations</h2>
          <div class="mt-2 grid grid-cols-3 gap-4">
            <div
              class="card bg-neutral text-neutral-content"
              v-for="invite in teamInvites"
              :key="invite.id"
            >
              <div class="card-body items-center text-center">
                <h2 class="card-title">{{ invite.team.name }}</h2>
                <p>
                  You have been invited to join team "{{ invite.team.name }}"
                </p>
                <div class="card-actions justify-end">
                  <button
                    class="btn btn-primary btn-sm"
                    type="button"
                    @click="updateInvite(invite.id, 'accept')"
                  >
                    Accept
                  </button>
                  <button
                    class="btn btn-ghost btn-sm"
                    type="button"
                    @click="updateInvite(invite.id, 'reject')"
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
          :class="{ 'mt-8': teamInvites.length }"
        >
          <TeamCard
            :team="team"
            @delete-team="deleteTeam"
            @leave-team="leaveTeam"
            v-for="team in teamsStore.teams"
            :key="team.id"
          />
        </div>
      </div>

      <ANModal v-if="isCreateTeamModalOpen" size="Medium">
        <template #title>Create New team</template>

        <div>
          <ANInput
            v-model="teamName"
            type="text"
            placeholder="Team Name"
            class="mb-4"
          />
          <ANInput
            v-model="teamDescription"
            type="text"
            placeholder="Team Description"
            class="mb-4"
          />
        </div>

        <template #action>
          <ANButton class="mr-2 w-full" @click="isCreateTeamModalOpen = false">
            Cancel
          </ANButton>
          <ANButton dark class="ml-2 w-full" @click="createTeam">
            <p>Create</p>
          </ANButton>
        </template>
      </ANModal>
    </GridContainer>
  </SidebarLayout>
</template>
