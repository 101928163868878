<template>
  <div class="z-40 flex flex-col md:px-4">
    <router-link
      v-for="{ route, label, icon, extraData } in routes"
      :key="route"
      :to="route"
      active-class="opacity-1 font-medium text-sky-500 md:text-sky-500"
      class="flex items-center rounded-lg p-2 text-base font-light text-gray-900 md:text-white md:hover:bg-gray-700"
    >
      <Component :is="icon" class="mr-2 h-5 w-5" />
      <p>
        {{ label }}
        <span
          v-if="extraData?.isNew"
          class="badge badge-primary ml-1 text-xs font-semibold"
          >New</span
        >
      </p>
    </router-link>
    <div v-if="teamsStore.teams.length" class="ml-6">
      <RouterLink
        v-for="team in teamsStore.teams"
        :key="team.id"
        :to="`/app/teams/${team.id}`"
        active-class="opacity-1 font-small text-sky-500 md:hover:text-yellow-400"
        class="flex items-center rounded-lg py-1 pl-3 text-sm font-medium text-gray-900 md:text-white md:hover:text-yellow-400"
      >
        {{ team.name }}
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { APP_ROUTES } from 'src/core/AppRoutes';
import {
  VideoCameraIcon,
  UserGroupIcon,
  HomeIcon,
  Squares2X2Icon,
} from '@heroicons/vue/24/outline';
import { useTeamsStore } from 'src/stores/teamsStore';
import { computed } from 'vue';

const teamsStore = useTeamsStore();

const routes = computed(() => {
  return [
    {
      route: APP_ROUTES.DASHBOARD,
      icon: HomeIcon,
      label: 'Dashboard',
    },
    {
      route: APP_ROUTES.VIDEOS,
      icon: VideoCameraIcon,
      label: 'Videos',
    },
    {
      route: APP_ROUTES.ALBUMS,
      icon: Squares2X2Icon,
      label: 'Albums',
    },
    {
      route: APP_ROUTES.TEAMS,
      icon: UserGroupIcon,
      label: 'Teams',
      extraData: {
        isNew: true,
      },
    },
  ];
});
</script>
