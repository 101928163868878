<template>
  <label ref="label" for="video-input">
    <ANButton :loading="isUploadInProgress" dark @click="initUpload" small>
      <template #loading>Uploading...</template>
      <div class="flex items-center">
        <Clapperboard class="h-4" />
        <span>Add Video</span>
      </div>
    </ANButton>

    <input
      id="video-input"
      type="file"
      class="absolute hidden"
      @change="upload(($event?.target as HTMLInputElement).files)"
      accept="video/*"
      multiple="false"
    />
  </label>
</template>

<script lang="ts">
export const EVENTS = {
  UPLOAD_STARTED: 'upload-started',
  UPLOAD_FINISHED: 'upload-finished',
  UPLOAD_FAILED: 'upload-failed',
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { Clapperboard } from 'lucide-vue-next';
import { ANButton } from 'src/components/Uikit/Components';
import { useEmitter } from 'src/core/EventEmitter';
import { VideoHttpService } from 'src/core/services/Video/VideoHttpService';

const props = defineProps<{
  teamId?: string;
}>();

const videoHttpService = new VideoHttpService();

const emitter = useEmitter();
const isUploadInProgress = ref(false);
const label = ref(null);

const initUpload = () => (label.value as unknown as HTMLLabelElement).click();

const upload = async (files: FileList | null) => {
  const fileToUpload = files?.item(0);

  if (!fileToUpload) {
    return;
  }

  emitter.emit(EVENTS.UPLOAD_STARTED);

  const formData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);

  isUploadInProgress.value = true;

  const response = props.teamId
    ? await videoHttpService.addTeamVideo(props.teamId, formData)
    : await videoHttpService.add(formData);

  isUploadInProgress.value = false;

  if (!response.ok) {
    emitter.emit(EVENTS.UPLOAD_FAILED);

    return;
  }

  emitter.emit(EVENTS.UPLOAD_FINISHED);
};
</script>
