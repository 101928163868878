<template>
  <div class="avatar" :class="[{ placeholder: !hasAvatar }]">
    <div
      :class="[{ 'bg-neutral text-neutral-content': !hasAvatar }, size]"
      class="w-fit-content rounded-full"
    >
      <img
        v-if="hasAvatar"
        :src="userAvatar.publicUrl"
        @error="hasAvatar = false"
      />
      <span v-else class="text-sm font-semibold">{{
        userAvatar.firstLetter
      }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppUser } from 'src/core/3rdParty/Firebase';
import { userAvatarRef } from 'src/core/services/storage-service';
import { ref, computed } from 'vue';
import { UserAvatarFile } from 'src/core/models/UserAvatarFile';

interface IProps {
  textClass?: string;
  size?: string;
  userAvatarFile?: UserAvatarFile | null;
}

const props = withDefaults(defineProps<IProps>(), {
  textClass: 'bg-sky-500 text-white',
  size: 'h-10 w-10',
  userAvatarFile: null,
});

const user = useAppUser();
const hasAvatar = ref(true);

const userAvatar = computed(() => {
  if (props.userAvatarFile) {
    return props.userAvatarFile;
  }

  return new UserAvatarFile(userAvatarRef(user?.uid ?? ''), {
    displayName: user?.displayName ?? '',
    email: user?.email ?? '',
  });
});
</script>
