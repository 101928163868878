<template>
  <RegistrationLayout class="an-gradient-bg relative">
    <h1 class="mb-9 mt-4 px-4 text-center text-2xl font-bold text-white">
      Log in to your account
    </h1>

    <div class="rounded-md bg-white p-12">
      <form>
        <ANInput
          v-model="input.email"
          label="Email"
          placeholder="Enter Your Email"
          @focus="clearError"
        />

        <ANInput
          v-model="input.password"
          class="mt-6"
          type="password"
          label="Password"
          placeholder="Enter Your Password"
          @focus="clearError"
        />

        <p
          class="an-dark--text mb-6 mt-4 text-right text-xs font-medium transition duration-200 hover:text-orange-500 hover:underline"
        >
          <RouterLink :to="{ name: PUBLIC_ROUTE_NAMES.RESET_PASSWORD }"
            >Reset password</RouterLink
          >
        </p>

        <ErrorNotification
          v-if="error"
          class="mt-4 block rounded-lg bg-white p-2 text-red-500"
        >
          <p class="text-sm text-red-500">
            Couldn't sign in. Please try again or get in touch with us via
            <a
              class="inline-flex whitespace-nowrap text-blue-500 transition duration-300 hover:text-primary"
              href="mailto:support@athleticnext.com"
              >support@athleticnext.com</a
            >
          </p>
        </ErrorNotification>

        <ANButton
          class="mt-6"
          :disabled="!canSubmit && !signInInProgress"
          dark
          full
          @click="submit"
        >
          <ANSpinner v-if="signInInProgress" light />
          <template v-else>Continue</template>
        </ANButton>

        <p class="an-dark--text my-4 text-center text-xs">or</p>
      </form>

      <GoogleLoginButton class="mt-3 h-10" />
      <AppleLoginButton class="my-3" />

      <p class="an-dark--text my-4 text-center text-sm">
        Not a member yet?
        <a
          href="/register"
          class="an-dark--text font-bold transition duration-200 hover:text-orange-500"
        >
          Create account
        </a>
      </p>
    </div>
  </RegistrationLayout>
</template>

<script setup lang="ts">
import { APP_ROUTES, PUBLIC_ROUTE_NAMES } from 'src/core/AppRoutes';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import RegistrationLayout from 'src/components/layout/RegistrationLayout.vue';
import ErrorNotification from 'src/components/common/ErrorNotification.vue';
import GoogleLoginButton from 'src/components/Login/GoogleLoginButton.vue';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { logUserLogin } from 'src/core/services/AnalyticsService';
import { auth } from 'src/core/3rdParty/Firebase';
import AppleLoginButton from 'src/components/Login/AppleLoginButton.vue';
import { ANInput, ANButton, ANSpinner } from 'src/components/Uikit/Components';

const router = useRouter();

const error = ref(false);
const input = ref({
  email: '',
  password: '',
});

const signInInProgress = ref(false);

const canSubmit = computed(() => !!input.value.email && !!input.value.password);

const submit = async () => {
  if (!canSubmit.value) {
    return;
  }

  signInInProgress.value = true;

  try {
    await signInWithEmailAndPassword(
      auth,
      input.value.email,
      input.value.password,
    );

    logUserLogin();
    router.push(APP_ROUTES.ROOT);
  } catch {
    error.value = true;
  } finally {
    signInInProgress.value = false;
  }
};

const clearError = () => (error.value = false);
</script>
