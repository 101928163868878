import { BaseBffHttpService } from '../BaseBffHttpService';

type CreateTeamRequest = {
  email: string;
};

export class TeamInvitesHttpService extends BaseBffHttpService {
  basePath = 'team-invites';

  create(teamId: string, body: CreateTeamRequest) {
    return this.post<CreateTeamRequest>(
      ['teams', teamId, 'invites'].join(this.pathSeparator),
      body,
    );
  }

  listMy(teamId: string) {
    return this.get(['teams', teamId, 'invites'].join(this.pathSeparator));
  }

  remove(inviteId: string) {
    return this.delete(
      ['teams', this.basePath, inviteId].join(this.pathSeparator),
    );
  }
}
