import { BaseBffHttpService } from '../BaseBffHttpService';

export type GetSelfTeamInvitesResponse = {
  items: Invite[];
};

export type Invite = {
  id: string;
  createdAt: Date;
  team: {
    id: string;
    name: string;
  };
};

export class TeamInvitesHttpService extends BaseBffHttpService {
  basePath = 'teams';

  getSelfTeamInvites = async (): Promise<GetSelfTeamInvitesResponse> => {
    const response = await this.get(
      ['self', this.basePath, 'invites'].join(this.pathSeparator),
    );

    return response.json();
  };
}
