<script setup lang="ts">
import { ref, defineEmits, computed } from 'vue';
import {
  EllipsisVertical,
  Mails,
  Users,
  Trash2,
  Shield,
  LogOut,
  FileVideo2,
} from 'lucide-vue-next';
import { TeamDto } from 'src/core/services/Teams/Teams.types';
import ContextCard from 'src/components/common/ContextCard.vue';

const props = defineProps<{
  team: TeamDto;
}>();

const emit = defineEmits(['delete-team', 'leave-team']);

const showMenu = ref(false);

const isTeamOwner = computed(() => {
  return props.team.role === 'owner';
});

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};

const deleteTeam = () => {
  emit('delete-team', props.team.id);
};

const leaveTeam = () => {
  emit('leave-team', props.team.id);
};
</script>

<template>
  <RouterLink
    :to="`/app/teams/${team.id}`"
    class="rounded-md bg-white shadow-sm hover:bg-slate-200"
  >
    <div class="card">
      <div class="card-body">
        <h2 class="card-title flex gap-1">
          {{ team.name }}
          <span
            v-if="isTeamOwner"
            class="tooltip font-normal"
            data-tip="Owned by me"
          >
            <Shield class="h-4 w-4 fill-blue-300 stroke-blue-600" />
          </span>
        </h2>
        <p>
          {{ team.description }}
        </p>
        <div class="flex gap-3">
          <div
            class="tooltip"
            :data-tip="`Team members ${team.members.length}`"
          >
            <div class="flex gap-1">
              <Users class="h-6 w-6" />
              {{ team.members.length }}
            </div>
          </div>
          <div
            v-if="isTeamOwner"
            class="tooltip"
            :data-tip="`Pending invites ${team.invites.length}`"
          >
            <div class="flex gap-1">
              <Mails class="h-6 w-6" />
              {{ team.invites.length }}
            </div>
          </div>
          <div
            class="tooltip"
            :data-tip="
              team.videoCount
                ? `Team videos ${team.videoCount}`
                : 'There are no videos in this team'
            "
          >
            <div class="flex gap-1">
              <FileVideo2 class="h-6 w-6" />
              {{ team.videoCount }}
            </div>
          </div>
          <ContextCard
            class="ml-auto"
            position="right"
            margin="z-50"
            @open="toggleMenu"
            @close="toggleMenu"
          >
            <div
              @click.prevent
              class="tooltip hover:text-blue-600"
              data-tip="More"
            >
              <EllipsisVertical class="h-6 w-6" />
            </div>
            <template #content>
              <div>
                <button
                  v-if="isTeamOwner"
                  class="flex w-full items-center rounded-md p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                  type="button"
                  @click.prevent="deleteTeam"
                >
                  <Trash2 class="mr-3 h-4 w-4" />
                  <span>Delete team</span>
                </button>
                <button
                  v-else
                  class="flex w-full items-center rounded-md p-2 text-sm font-normal leading-6 transition duration-200 hover:bg-secondary-light"
                  type="button"
                  @click.prevent="leaveTeam"
                >
                  <LogOut class="mr-3 h-4 w-4" />
                  <span>Leave team</span>
                </button>
              </div>
            </template>
          </ContextCard>
        </div>
      </div>
    </div>
  </RouterLink>
</template>
